import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { CgMail, CgWebsite } from "react-icons/cg";
import { BsGithub, BsInstagram, BsLinkedin, BsPhone, BsYoutube } from "react-icons/bs";
import { MdCall } from "react-icons/md";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", height: "100vh", alignItems: "center" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "3px",
              paddingBottom: "5px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", textAlign: "center" }}>
              Contact Information
            </h1>
            <Row className="justify-content-center">
              <Col xs={12} md={8} className="contactbox">
                <p><strong>Email <CgMail/> :</strong> khrish007.123@gmail.com</p>
                <p><strong>Phone <MdCall/> :</strong> +91 7990741233</p>
                <p><strong>Website <CgWebsite/> :</strong> <a href="https://www.khrish.tech" target="_blank" rel="noopener noreferrer">www.khrish.tech</a></p>
                <p><strong> SpidyRockss <BsYoutube/> :</strong> <a href="https://www.youtube.com/@SpidyRockss" target="_blank" rel="noopener noreferrer">youtube.com/@SpidyRockss</a></p>
                <p><strong> TechieSpidy <BsYoutube/> :</strong> <a href="https://www.youtube.com/@TechieSpidy" target="_blank" rel="noopener noreferrer">youtube.com/@TechieSpidy</a></p>
                <p><strong>LinkedIn <BsLinkedin/> :</strong> <a href="https://www.linkedin.com/in/khrish-doshi-6a1b6a320/" target="_blank" rel="noopener noreferrer">linkedin.com/in/khrish-doshi/</a></p>
                <p><strong>GitHub <BsGithub/> :</strong> <a href="https://github.com/Dev-Khrish" target="_blank" rel="noopener noreferrer">github.com/Dev-Khrish</a></p>
                <p><strong>Instagram <BsInstagram/> :</strong> <a href="https://www.instagram.com/spidyrockss/" target="_blank" rel="noopener noreferrer">instagram.com/spidyrockss/</a></p>
              </Col>
            </Row>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            {/* You can place any image or content here if needed */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
